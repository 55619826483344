import { BrowserRouter } from 'react-router-dom'
import { WebRouter } from './router/WebRouter'

export const WelcGroup = () => {
    return (
        <BrowserRouter>
            <WebRouter />
        </BrowserRouter>
    )
}
