import { FaFacebookF, FaWhatsapp, FaEnvelope, FaTwitter } from 'react-icons/fa';

export const Footer = () => {
    return (
        <div className='footer__container mt-5'>
            <div className='footer__flex-items'>
                <div>
                    {/* <h3 className='mt-4'>ADDRESS. 500 TERRY FRANCOIS STREET SAN FRANCISCO, CA 94158</h3> */}
                    <h3 className='mt-4'>LATIN AMERINCAN HEADQUARTERS: MEDELLIN, COLOMBIA</h3>
                </div>

                <div className='footer__icons mt-5 mb-5'>
                    <FaWhatsapp />
                    <FaFacebookF />
                    <FaEnvelope />
                    <FaTwitter />
                </div>

                <div>
                    <h3 style={{
                        margin: '0',
                    }}>2022 WELC™ GROUP GLOBAL</h3>
                </div>
            </div>
        </div>
    )
}
