import { useRef } from "react";
import { Helmet } from "react-helmet";
import { Button, Container } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useFormik } from "formik";
import Swal from 'sweetalert2';
import * as Yup from 'yup';

export const HomePage = () => {

    const form = useRef();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        initialValues: {
            name: '',
            last: '',
            email: '',
            phone: '',
            message: ''
        },
        onSubmit: values => {
            console.log(values);
            emailjs.sendForm('service_elxo8mr', 'template_cd533bx', form.current, '8_6TH-IxPPzxN9XPY')
                .then(res => {
                    console.log(res.text);
                    Toast.fire({
                        icon: 'success',
                        title: 'Form sent successfully'
                    })
                })
                .catch(err => {
                    console.log(err.text);
                    Toast.fire({
                        icon: 'error',
                        title: 'Form sending failed'
                    })
                })
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(40, 'Must be 40 characters or less')
                .required('Required'),
            last: Yup.string()
                .max(40, 'Must be 40 characters or less')
                .required('Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            phone: Yup.string()
                .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Invalid phone number')
                .required('Required'),
            message: Yup.string()
                .max(1000, 'Must be 1000 characters or less')
                .required('Required')
        })
    });




    return (
        <div>
            <Helmet>
                <title>Home - Welc™ Group</title>
                <meta name="description" content="Home page of Welc™ Group" />
                <meta name="keywords" content="Home page, Welc™ Group" />
                <meta name="author" content="Welc™ Group" />
                <property name="og:title" content="Home - Welc™ Group" />
                <property name="og:description" content="Home page of Welc™ Group" />
            </Helmet>
            <div className="home__container-position">
                <div className="home__image-container">
                    <img
                        src="https://welcgroupimages.s3.amazonaws.com/home_image.jpg"
                        alt="home_image"
                    />
                </div>

                <div className="home__overlay">
                    <div className="home__overlay-content">
                        <h1>BECOME PART OF</h1>
                        <h1 className="mb-5">WELC™ GROUP GLOBAL</h1>
                        <p className="mb-5">We are a group of companies dedicated to cellular wireless solutions, spanning from rural solutions (as an alternative to costly and lagging satellite internet), camping and mobile home 5G/4G LTE solutions, and marine solution</p>
                        <Button
                            variant="secondary"
                            className="mt-3"
                            style={{
                                padding: '1.5rem',
                                display: 'flex',
                                justifyContent: 'center ',
                                alignItems: 'center',
                                textTransform: 'uppercase',
                                fontSize: '1.2rem',
                                letterSpacing: '0.1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            I want to know more
                        </Button>
                    </div>
                </div>
            </div>
            <Container>
                <div className="home__services-header">
                    <h1 className="mb-3" id="services">Services</h1>
                    <h3 className="mb-3">We bring you fast internet!</h3>
                    <p>We will install our mission-critical systems anywhere, up to 30 km away from a cellular service tower, to capture even the faintest cellular signal for maximum upload and downloads data speeds</p>
                </div>

                <div className="home__services-image-container">
                    <img
                        src="https://welcgroupimages.s3.amazonaws.com/welc_image.jpg"
                        alt="welc_image"
                    />
                </div>

                <div className="home__services-header">
                    <h1 className="mb-3" id="findUs">Find Us</h1>
                    <h3 className="mb-3">Our Ever-Expanding Network of Companies Is Near You</h3>
                    <p>We’re currently own the USA, Latin America, Asia, and Europe. Contact us and we will contact you from our nearest location.</p>
                </div>

                <div className="home__services-image-container">
                    <img
                        src="https://welcgroupimages.s3.amazonaws.com/Map.jpg"
                        alt="welc_image"
                    />
                </div>

                <div className="home__flex-container mt-4" style={{
                    backgroundColor: '#120872',
                    color: '#fff',
                }}>
                    <div className="home__card-container">
                        <h2 id="story">OUR STORY</h2>
                        <p>We are a group of companies dedicated to cellular wireless solutions, spanning from rural solutions (as an alternative to costly and lagging satellite internet), camping and mobile home 5G/4G LTE solutions, and marine solutions</p>
                    </div>

                    <div className="home__card-container">
                        <h2>Vision</h2>
                        <p>We want to cover every corner of the planet thanks to a dedicated network of professional installers, to bring fast and reliable 5G/4G LTE internet anywhere.</p>
                    </div>

                    <div className="home__card-container">
                        <h2>Technology</h2>
                        <p>We exclusively use WirEng® technology because our mission-critical applications require maximum sensitivity, reliability, and durability</p>
                    </div>
                </div>


                {/* <div className="home__services-header">
                    <h1 className="mb-3" id="aboutUs">Look what people says</h1>
                </div>

                <div className="home__testimonial-container">
                    <div className="testim-image-container">
                        <img
                            src="https://welcgroupimages.s3.amazonaws.com/Richard+Heiman.jpg"
                            alt="Richard Heiman"
                        />
                    </div>

                    <div className="testimonial-content">
                        <h1> Richard Heiman | CEO at Mikron</h1>
                        <p>“I'm a testimonial. Click to edit me and add text that says something nice about you and your services. Let your customers review you and tell their friends how great you are.</p>
                    </div>
                </div> */}

                <div className="home__services-header">
                    <h1 className="mb-3" id="contact">BECOME PART OF WELC™</h1>
                    <p>If you already are a professional installer, you will complete your portfolio of solutions with our exclusive technology, and if you’re thinking about becoming your own boss by owning your own company associated with WELC™, we will support you and train you to be the very best in this field, thanks to our extensive  and unparalleled hands-on experience</p>
                </div>

                <div>
                    <form ref={form} onSubmit={handleSubmit}>
                        <div className="home__form-container">
                            <input
                                type="text"
                                placeholder="First Name"
                                {...getFieldProps('name')}
                                autoComplete="off"
                            />

                            {touched.name && errors.name && <p className="contact__error">{errors.name}</p>}

                            <input
                                type="text"
                                placeholder="Last Name"
                                {...getFieldProps('last')}
                                autoComplete="off"
                            />

                            {touched.last && errors.last && <p className="contact__error">{errors.last}</p>}

                            <input
                                type="email"
                                placeholder="Email"
                                {...getFieldProps('email')}
                                autoComplete="off"
                            />

                            {touched.email && errors.email && <p className="contact__error">{errors.email}</p>}

                            <input
                                type="text"
                                placeholder="Phone"
                                {...getFieldProps('phone')}
                                autoComplete="off"
                            />

                            {touched.phone && errors.phone && <p className="contact__error">{errors.phone}</p>}

                            <textarea
                                placeholder="Message"
                                {...getFieldProps('message')}
                            ></textarea>

                            {touched.message && errors.message && <p className="contact__error">{errors.message}</p>}
                        </div>
                        <Button
                            variant="secondary"
                            className="mt-3"
                            type="submit"
                            style={{
                                padding: '1.5rem',
                                display: 'flex',
                                justifyContent: 'center ',
                                alignItems: 'center',
                                textTransform: 'uppercase',
                                fontSize: '1.2rem',
                                letterSpacing: '0.1rem',
                                fontWeight: 'bold',
                                width: '100%',
                            }}
                        >
                            Submit
                        </Button>
                    </form>
                </div>
            </Container>
        </div>
    )
}
