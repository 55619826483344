import { Routes, Route } from 'react-router-dom';
import { Footer, NavbarComponent } from '../components/ui';

import { HomePage } from '../page/HomePage'

export const WebRouter = () => {
    return (
        <>
            <NavbarComponent />
            <Routes>
                <Route path='/' element={<HomePage />} />
            </Routes>
            <Footer />
        </>
    )
}
