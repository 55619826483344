import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';

export const NavbarComponent = () => {

  const navigate = useNavigate();

    const handleHomePage = () => {
        navigate('/');
    }

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="navbar__container">
        <Container fluid>
          <Navbar.Brand>
            <div className="navbar__logo-container">
              <img
                src="https://welcgroupimages.s3.amazonaws.com/welc_logo.jpg"
                alt="Logo"
                className="navbar__logo pointer"
                onClick={handleHomePage}
                id="nav-top"
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto text-uppercase d-flex justify-content-evenly">
              <a href="#services" className='link'>Services</a>
              <a href="#findUs" className='link'>Find us</a>
              <a href="#story" className='link'>Partners</a>
              <a href="#aboutUs" className='link'>About us</a>
              <a href="#contact" className='link'>Get in Touch</a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
